import queryString from "query-string"
import React, { Component, lazy } from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { bindActionCreators } from "redux"
import io from "socket.io-client"

import ACTIONS from "../constants/ACTIONS"
import * as appActions from "../redux/actions"
import { store } from "../redux/store"
import apiUtils from "../utils/api-utils"
import config from "../utils/config"
import { GLOBAL_PERMISSIONS, PERMISSIONS } from "../utils/team-management-utils"
import userBehaviourUtils from "../utils/userBehaviour-utils"
import { loadExternalENVUrl, WHITELABEL_PERMISSIONS } from "../utils/whitelabel-utils"
import AuthUserRoute from "./atoms/AuthUserRoute"
import GuestUserRoute from "./atoms/GuestUserRoute"
import CardWrapper from "./organisms/CardWrapper"
import WhitelabelWidget from "./organisms/WhitelabelWidget"
import AllIconsPage from "./pages/AllIconsPage"
import CiCDPage from "./pages/CiCDPage"

const TeamManagementPage = lazy(() => import("./pages/TeamManagementPage"))
const CampaignEditPage = lazy(() => import("./pages/CampaignEditPage"))
const Campaigns = lazy(() => import("./pages/Campaigns"))
const ChatPage = lazy(() => import("./pages/ChatPage"))
const FeatureRequestsPage = lazy(() => import("./pages/FeatureRequestsPage"))
const FeatureRequestPage = lazy(() => import("./pages/FeatureRequestPage"))
const HelpCustomVariables = lazy(() => import("./pages/HelpCustomVariables"))
const LoadingPage = lazy(() => import("./pages/LoadingPage"))
const Login = lazy(() => import("./pages/LoginPage"))
const TestPage = lazy(() => import("./pages/TestPage"))
const CreateCampaignInfoPage = lazy(() => import("./pages/CreateCampaignInfoPage"))
const CreateCampaignSettingsPage = lazy(() => import("./pages/CreateCampaignSettingsPage"))
const CreateCampaignComplexSteps = lazy(() => import("./pages/CreateCampaignComplexSteps"))
const MasterLoginPage = lazy(() => import("./pages/MasterLoginPage"))
const Register = lazy(() => import("./pages/RegisterPage"))
const SelectedCampaigns = lazy(() => import("./pages/SelectedCampaigns"))
const SettingsPage = lazy(() => import("./pages/SettingsPage"))
const SomethingWentWrong = lazy(() => import("./pages/SomethingWentWrongPage"))
const StatisticsPage = lazy(() => import("./pages/StatisticsPage"))
const WebhooksPage = lazy(() => import("./pages/WebhooksPage"))
const UnclaimedPage = lazy(() => import("./pages/UnclaimedPage"))
const PermissionDeniedPage = lazy(() => import("./pages/PermissionDeniedPage"))
const LinkedinOpenProfilePage = lazy(() => import("./pages/LinkedinOpenProfilePage"))
const MaintenancePage = lazy(() => import("./pages/MaintenancePage"))
const GlobalBlacklistPage = lazy(() => import("./pages/GlobalBlacklistPage"))

apiUtils.initInterceptors()

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const { history, location } = this.props
    history.listen(currentLocation => {
      userBehaviourUtils.navigated(currentLocation.pathname)
    })

    const params = queryString.parse(location.search)
    if (params.iframe) {
      localStorage.setItem("iframe", "true")
    }
    const whitelabelENVUrl = localStorage.getItem("whitelabelUrl")
    const { fpr: referral, whitelabelUrl } = params

    /** Load external CSS used for whitelabel color preview */
    if (whitelabelUrl) {
      loadExternalENVUrl(whitelabelUrl)
    } else if (whitelabelENVUrl) {
      /** Load external CSS and ENV for whitelabel */
      loadExternalENVUrl(whitelabelENVUrl)
    }

    if (referral) localStorage.setItem("referral", referral)

    this.loadCrispChat()

    this.paginationFix()

    this.webSocketConfiguration()

    this.hotjarInit()

    if (this.props.userID !== undefined) {
      this.productFruitsInit()
    }

    this.setState({ loading: false })
  }

  componentDidUpdate(prevProps) {
    const { userID } = this.props
    if (userID !== prevProps.userID && userID !== undefined) {
      this.productFruitsInit()
    }
  }

  hotjarInit = () => {
    if (window.hj) {
      const { userID } = this.props
      window.hj("identify", userID, {})
    }
  }

  productFruitsInit = () => {
    if (window.$productFruits) {
      const {
        userID,
        user: { email, fullName, userTypeId, createdAt, whitelabelId, hasActiveSubscription },
      } = this.props
      const userInfo = {
        username: userID,
        email,
        signUpAt: createdAt,
        props: {
          fullName,
          userTypeId,
          whitelabelId,
          hasActiveSubscription,
        },
      }
      if (config.REACT_APP_PRODUCT_FRUITS_COMPANY) {
        window.$productFruits.push([
          "init",
          config.REACT_APP_PRODUCT_FRUITS_COMPANY,
          "en",
          userInfo,
        ])
      }
    }
  }

  paginationFix = () => {
    const maxOnPage = localStorage.getItem("maxOnPage")
    if ([200, 500].includes(+maxOnPage)) {
      localStorage.removeItem("maxOnPage")
    }
  }

  accountStatusChangeSocket = socket => {
    const { actions, history, userID } = this.props

    socket.on(
      "AccountAuthChange",
      async ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl },
        data: { message, accountGlobalStatusId },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              history.push(onClickRedirectUrl)
            }
          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
        await actions.getTeamAccounts()
        if (+accountGlobalStatusId === 3) {
          history.replace(`/users/${userID}/`)
        }
      },
    )
  }

  infoSocket = socket => {
    const { history } = this.props
    socket.on(
      "Info",
      ({
        metadata: { messageType, userPopupMessage, onClickRedirectUrl, duration },
        data: { message },
      }) => {
        if (userPopupMessage) {
          const options = {}
          if (onClickRedirectUrl)
            options.onClick = () => {
              history.push(onClickRedirectUrl)
            }
          if (duration) options.autoClose = duration

          switch (messageType) {
            case "SUCCESS":
              toast.success(message, options)
              break
            case "WARNING":
              toast.warn(message, options)
              break
            case "ERROR":
              toast.error(message, options)
              break
            default:
              toast.info(message, options)
              break
          }
        }
      },
    )
  }

  chatMessageSocket = (socket, eventType) => {
    const { linkedinUserMessages } = this.props
    const newMessageStatus = eventType === "SendMessageSuccess" ? "SENT/RECEIVED" : "ERRORED"

    socket.on(eventType, ({ data: { messageId } }) => {
      const newLinkedinUserMessages = {
        ...linkedinUserMessages,
        channels: {
          ...linkedinUserMessages.channels,
          ...Object.entries(linkedinUserMessages.channels).reduce(
            (channelsResult, [channelId, channel]) => {
              channelsResult[channelId] = {
                ...channel,
                threads: {
                  ...channel.threads,
                  ...Object.entries(channel.threads).reduce((threadsResult, [threadId, thread]) => {
                    threadsResult[threadId] = thread.map(message => {
                      if (message.id === messageId) {
                        return {
                          ...message,
                          messageStatus: newMessageStatus,
                        }
                      }
                      return message
                    })
                    return threadsResult
                  }, {}),
                },
              }
              return channelsResult
            },
            {},
          ),
        },
      }
      store.dispatch({
        type: ACTIONS.GET_LINKEDIN_USER_MESSAGES,
        linkedinUserMessages: newLinkedinUserMessages,
      })
    })
  }

  notificationSocket = socket => {
    socket.on("NewNotification", ({ data }) => {
      const { accountNotifications } = this.props
      const { activeAccountID } = store.getState().account
      if (+data.linkedinAccountId === +activeAccountID) {
        const allNotifications = {
          count: accountNotifications.count + 1,
          notifications: [data].concat(accountNotifications.notifications),
          unseen: accountNotifications.unseen + 1,
        }

        store.dispatch({
          type: ACTIONS.GET_ACCOUNT_NOTIFICATIONS,
          accountNotifications: allNotifications,
        })
        toast.success("You have received a new notification")
      }
    })
  }

  streamError = socket => {
    socket.on("StreamError", () => {
      toast.error("Something went wrong with stream. Please try again later.")
      store.dispatch({ type: ACTIONS.STREAM_ERROR })
    })
  }

  streamSuccess = socket => {
    socket.on("StreamSuccess", () => {
      store.dispatch({ type: ACTIONS.STREAM_SUCCESS })
    })
  }

  webSocketConfiguration = () => {
    const socket = io(config.REACT_APP_SOCKET_URL, {
      withCredentials: true,
      transports: ["websocket", "polling"],
    })

    this.accountStatusChangeSocket(socket)

    this.infoSocket(socket)

    this.chatMessageSocket(socket, "SendMessageSuccess")

    this.chatMessageSocket(socket, "SendMessageError")

    this.notificationSocket(socket)

    this.streamError(socket)

    this.streamSuccess(socket)
  }

  loadCrispChat = () => {
    if (config.REACT_APP_USER_API_KEY === "undefined") {
      /** Include the Crisp code here, without the <script></script> tags */
      window.$crisp = []
      window.CRISP_WEBSITE_ID =
        config.REACT_APP_CRISP_WEBSITE_ID !== "undefined"
          ? config.REACT_APP_CRISP_WEBSITE_ID
          : undefined
      ;(() => {
        const d = document
        const s = d.createElement("script")

        s.src = "https://client.crisp.chat/l.js"
        s.async = 1
        d.getElementsByTagName("head")[0].appendChild(s)
      })()
    }
  }

  render() {
    const { loading } = this.state

    if (config.REACT_APP_UNDER_MAINTENANCE) return <MaintenancePage />
    if (loading) return <LoadingPage />
    return (
      <CardWrapper>
        <Switch>
          <GuestUserRoute exact path="/test" component={TestPage} />
          <GuestUserRoute exact path="/login" component={Login} />
          <GuestUserRoute exact path="/register" component={Register} />
          <GuestUserRoute
            exact
            path="/all-icons-for-the-best-design-team"
            component={AllIconsPage}
          />
          <GuestUserRoute exact path="/ci-cd-countdown" component={CiCDPage} />
          <AuthUserRoute exact path="/helpcenter/customvariables" component={HelpCustomVariables} />
          <AuthUserRoute exact path="/unclaimed" component={UnclaimedPage} />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/permission-denied"
            component={PermissionDeniedPage}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/*/permission-denied"
            component={PermissionDeniedPage}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/global-blacklist/permission-denied"
            component={PermissionDeniedPage}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/team-management/permission-denied"
            component={PermissionDeniedPage}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/team-management/permission-denied"
            component={PermissionDeniedPage}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/chat/:campaignID/:linkedinUserID"
            component={ChatPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
            permission={PERMISSIONS.CHAT_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/chat/:campaignID"
            component={ChatPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
            permission={PERMISSIONS.CHAT_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/chat"
            component={ChatPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.INBOX}
            permission={PERMISSIONS.CHAT_VIEW}
          />
          <AuthUserRoute exact path="/users/:userID/" component={MasterLoginPage} />
          {/* Removed for now, for performance reasons */}
          {/* <AuthUserRoute exact path="/users/:userID/teams/:teamID" component={MasterLoginPage} /> */}
          <AuthUserRoute exact path="/" />
          <AuthUserRoute
            exact
            path="/users/:userID/team-management"
            component={TeamManagementPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.TEAM_MANAGEMENT}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/team-management"
            component={TeamManagementPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.TEAM_MANAGEMENT}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID"
            component={Campaigns}
            permission={PERMISSIONS.CAMPAIGNS_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/selected-campaigns/:campaignID"
            component={SelectedCampaigns}
            permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/selected-campaigns"
            component={SelectedCampaigns}
            permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/campaign/:campaignID/edit"
            component={CampaignEditPage}
            permission={PERMISSIONS.CAMPAIGN_DETAIL_VIEW}
          />

          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/info"
            component={CreateCampaignInfoPage}
            permission={PERMISSIONS.CAMPAIGNS_EDIT}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/settings"
            component={CreateCampaignSettingsPage}
            permission={PERMISSIONS.CAMPAIGNS_EDIT}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/create-campaign/smart-steps"
            component={CreateCampaignComplexSteps}
            permission={PERMISSIONS.CAMPAIGNS_EDIT}
          />
          {/* Global permission is necessary, for the case when user has no permission that allows them */}
          {/* viewing Settings page, but has Global permission for Blacklist, and hence should be able to access it */}
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/settings"
            component={SettingsPage}
            permission={PERMISSIONS.SETTINGS_VIEW}
            globalPermission={GLOBAL_PERMISSIONS.BLACKLIST}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/webhooks"
            component={WebhooksPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.WEBHOOKS}
            permission={PERMISSIONS.WEBHOOKS_VIEW}
          />

          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/statistics"
            component={StatisticsPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.ANALYTICS}
            permission={PERMISSIONS.CAMPAIGN_REPORTS_VIEW}
          />

          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/feature-requests"
            component={FeatureRequestsPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.FEATURE_SUGGESTIONS}
          />

          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/feature-requests/:featureID"
            component={FeatureRequestPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.FEATURE_SUGGESTIONS}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/accounts/:accountID/stream"
            component={LinkedinOpenProfilePage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.LINKEDIN_STREAM}
            permission={PERMISSIONS.OPEN_LINKEDIN_PROFILE_VIEW}
          />
          <AuthUserRoute
            exact
            path="/users/:userID/teams/:teamID/global-blacklist"
            component={GlobalBlacklistPage}
            whitelabelPermission={WHITELABEL_PERMISSIONS.BLACKLIST}
            globalPermission={GLOBAL_PERMISSIONS.BLACKLIST}
          />
          <Route component={SomethingWentWrong} />
        </Switch>
        {config.REACT_APP_WHITELABEL_SWITCHER && <WhitelabelWidget />}
      </CardWrapper>
    )
  }
}

const mapStateToProps = state => ({
  linkedinUserMessages: state.chat.linkedinUserMessages,
  user: state.user.profile,
  userID: state.user.profile.id,
  accountNotifications: state.account.accountNotifications,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
